<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">标题：</span>
					<el-input v-model="searchinput" placeholder="请输入标题" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<span style="width: 0.9rem;">所属分类：</span>
					<el-cascader v-model="searchtype" :props="props" @change="changejilian" clearable placeholder="请选择" style="width: 2.5rem;"></el-cascader>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>					
					<el-button type="success" @click="addnew">新增新闻</el-button>
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="220" @getdata="gettable">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showedit(scope.row)" type="warning" size="mini">编辑</el-button>
							<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
							<el-button v-if="scope.row.xstype==1" @click="yc(scope.row)" type="warning" size="mini">隐藏</el-button>
							<el-button v-if="scope.row.xstype==2" @click="fabu(scope.row)" type="primary" size="mini">发布</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			let that = this;
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				searchtype:"",
				searchtype2:"",
				tableHeader: [{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '所属分类',
						prop: 'classify_name',
						width: ''
					},
					{
						name: '阅读量',
						prop: 'readcount',
						width: ''
					},
					{
						name: '排序',
						prop: 'weigh',
						width: '120'
					},
					{
						name: '发布时间',
						prop: 'fbtime',
						width: ''
					}
				],
				tableData: [],
				props: {
					lazy: true,
					lazyLoad(node, resolve) {
						that.$post({
							url: '/api/classify/index',
							params: {
								pid: node.level==0?0:node.value,
								p: 1,
								size: 9999
							}
						}).then((res) => {
							res.list = res.list.map(item => ({
									value: item.id,
									label: item.name,
									leaf: item.ej.length>0?false:true
								}))
							resolve(res.list);
						})
					}
				},
				
			}
		},
		mounted() {			
			this.gettable()
		},
		activated() {
		    this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			changejilian(e){
				console.log(e)
				if(this.searchtype.length>=2){//有二级
					this.searchtype2 = e[1]
					
				}else if(this.searchtype.length==1){//没有二级
					this.searchtype2 = e[0]
				}else{
					this.searchtype2 = ''
				}
				this.gettable()
			},
			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			addnew() {
				this.$router.push('/system/news_add')
			},
			reset() {
				this.searchinput = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/news/adminindex',
					params: {
						name:this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						classify_id:this.searchtype2
					}
				}).then((res) => {
					
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			fabu(row){
				this.$confirm('确认发布该条新闻吗？')
					.then(_ => {
						this.$post({
							url: '/api/news/edit',
							params: {
								id: row.id,
								xstype:1
							}
						}).then((res) => {
							this.$message.success('操作成功')
							this.gettable()
						})
					})
					.catch(_ => {});
			},
			yc(row){
				this.$confirm('确认隐藏该条新闻吗？')
					.then(_ => {
						this.$post({
							url: '/api/news/edit',
							params: {
								id: row.id,
								xstype:2
							}
						}).then((res) => {
							this.$message.success('操作成功')
							this.gettable()
						})
					})
					.catch(_ => {});
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/api/news/del',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({
					path: '/system/news_edit',
					query: {
						id: row.id
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>